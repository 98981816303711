// 
// user.js
// Use this to write your custom JS
//

let dapi_url = "https://dapi.bhala.ai/api/"
let chat_username_form = document.getElementById('demo_chat_form');
var uname = document.getElementById('uname');
let chatBox = document.getElementById('chat-box');
let uinputRow = document.getElementById('uinput-row');
let uinput = document.getElementById('uinput');
let pinput = document.getElementById('pinput');
let ssubmit = document.getElementById('spell-submit');
let psubmit = document.getElementById('pred-submit');
let sresponse = document.getElementById('spell-response');
//username form
if (chat_username_form != null) {
chat_username_form.addEventListener("submit", event => {
    chat_username_form.hidden = true;
    uname = uname.value;
    chatBox.hidden = false;
    uinputRow.hidden = false;
    document.getElementById('form-row').hidden = true;
    let html = `Hi ${uname} How can I help you with your banking needs?`;
    addAgentText(html);
    event.preventDefault();
  });
}

let addAgentText = (txt) => {
  let agentDiv = document.createElement("div");
  agentDiv.innerHTML = `<p>bot: ${txt}</p>`; 
  updateChatbox(agentDiv);
}

let addClientText = (txt) => {
  let clientDiv = document.createElement("div");
  clientDiv.innerHTML = `<p>${uname}: ${txt}</p>`; 
  updateChatbox(clientDiv);
  uinput.value = '';
}

let updateChatbox = (childNode) => {
    chatBox.appendChild(childNode);
    chatBox.scrollTop = chatBox.scrollHeight - chatBox.clientHeight;
}

if (uinput != null) {
  //submit pressed
  document.getElementById('chat-submit').addEventListener('click', (e) => {
    e.preventDefault();
    if (uinput.value.length == 0) return;
    let userText = uinput.value;
    addClientText(userText);
    getChatResponse(userText);
  }); 
  
  // enter key pressed
  uinput.addEventListener('keypress', (e) => {
    let userText = uinput.value;
    if (e.key === 'Enter') {
      if (uinput.value.length == 0) return;
      addClientText(userText);
      getChatResponse(userText);
    }  
  });
}
 
if (psubmit != null) {
  psubmit.addEventListener('click', (e) => {
	e.preventDefault();
	if (pinput.value != null) {
	   getPredResponse(pinput.value);
        }
  }); 
}

async function getChatResponse(sentence) {
  s = encodeURIComponent(sentence)
  let resp = await fetch("https://dapi.bhala.ai/api/chat/en/" + s).then(resp => {return resp.json()})
  addAgentText(resp.intent);
}

async function getPredResponse(word) {
  let currVal = pinput.value;
  pinput.value = 'loading...'
  let resp = await fetch("https://dapi.bhala.ai/api/pred/zu/" + word).then(resp => {return resp.json()})
  pinput.value = `${currVal} ${resp.text}`;
}

//spell

// Quill Editor
const limit = 1000;
var num = 0;
var dict = {}
 Object.defineProperty(String.prototype, 'hashCode', {
  value: function() {
    var hash = 0, i, chr;
    for (i = 0; i < this.length; i++) {
      chr   = this.charCodeAt(i);
      hash  = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }
});
     
// Implement and register module
Quill.register('modules/counter', function(quill, options) {
  var container = document.querySelector('#counter');
  quill.on('text-change', function() {
    var text = quill.getText();
    // There are a couple issues with counting words
    // this way but we'll fix these later
    num = text.split(/\s+/).length - 1;
    if (text[0].charCodeAt() == 10) {
      num = 0;
    }
    // Limit words
    if (num > limit) {
        quill.history.undo();
        return;
    }
    container.innerText = 'words: ' + num.toString() + '/ ' + limit.toString();
  });
});


var lang = 'zu'; 
const setLang = (e) => {
  lang = e;
  quill.setText('')
}


// https://quilljs.com/docs/modules/toolbar/
Quill.register('modules/zu_sp', function(quill, options) {
  var space = false;
  var prevTextHash = ''.hashCode();
  quill.on('text-change', function(delta, oldDelta, source) {
    if (source != 'user' || num > limit) {
      return;
    }
    var text = quill.getText();
    // There are a couple issues with counting words
    // this way but we'll fix these later
	  var words = text.split(/\s+/);
    var start = 0;
		for (let i in words) {
      var word = words[i];
			if (word.length <= 2) {
				continue;
			} 

      console.log(words)
            
      var key = lang + word;
      console.log(key)
			if (dict[key]) {
					 quill.formatText(text.indexOf(word, start), word.length, {'color': false });
				 } else if(dict[key] == false) {
					 quill.formatText(text.indexOf(word, start), word.length, {'color': 'rgb(255, 0, 0)'});
			} else {
				var route = `${dapi_url}spell/${lang}/${word}`
				fetch(route)
				 .then(resp => resp.text())
				 .then(text =>  {
					 let json = JSON.parse(text)
					 dict[key] = json.ok; 
				 }); 
      } 
      start = start + word.length
		 }
  });
});

// Create Options
let intro = "Begin writing!"; 
let options = {
  modules: {
    toolbar: false,
    counter: true,
    zu_sp: true
  },
  scrollingContainer: '#scrolling-container',
  placeholder: intro,
  theme: 'bubble'  // or 'bubble'
}
var quill = new Quill('#editor', options);
quill.root.setAttribute('spellcheck', false)

